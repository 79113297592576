<template lang="pug">
  Section.cy-cancellation-period-section(
    required
    contains-dropdown
    :class="{ 'invalid-section': isInvalidSection }"
    :title="$t('plan_settings.basic_settings.sections.cancellation_period.section_name')"
  )
    .row.no-gutters.w-100
      .col
        AppDropdown.cy-cancellation-period-type(
          close-on-select
          order-direction="keep"
          :class="{ invalid: periodTypeError }"
          :items="items"
          :value="selectedPeriodType"
          @select="handleSelect"
        )
      .col
        .inputs
          span {{ $t("plan_settings.basic_settings.period.before_departure") }}
          AppNumberInput.value(
            v-if="object.period_type.$model === endDate"
            v-model="object.end_day.$model"
            :invalid="endDayError"
          )
          span.value(v-else) {{ "1" }}
          span {{ endDateDaysLabel }}
          AppNumberInput.value(
            v-if="object.period_type.$model === endDate"
            v-model="object.end_hour.$model"
            :invalid="endHourError"
            :max="24"
          )
          span.value(v-else) {{ "0" }}
          span {{ $t("plan_settings.basic_settings.period.o_clock_earlier") }}
</template>

<script>
  import { END_DATE } from "@/config/constants"
  import { resetValidatorValues } from "@/helpers/common"

  import { find } from "lodash-es"
  import { getCancellationPeriodOptions } from "@/config/translations_helper"

  export default {
    props: {
      object: Object
    },

    data() {
      return {
        endDate: END_DATE
      }
    },

    computed: {
      items() {
        return getCancellationPeriodOptions(this.$i18n)
      },

      selectedPeriodType() {
        return find(this.items, { id: this.object.period_type.$model })
      },

      periodTypeError() {
        return this.object.period_type.$error
      },

      endDayError() {
        return this.object.end_day.$error
      },

      endHourError() {
        return this.object.end_hour.$error
      },

      isInvalidSection() {
        return this.periodTypeError || this.endDayError || this.endHourError
      },

      endDateDaysLabel() {
        return this.$t(`plan_settings.basic_settings.period.${this.object.end_day.$model > 1 ? "days" : "day"}`)
      }
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    methods: {
      handleSelect({ id }) {
        resetValidatorValues(this.object, ["end_day", "end_hour"])

        this.object.period_type.$model = id
        this.object.$reset()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .row
    +styled-inputs

    .inputs
      align-items: center
      display: flex
      margin: 0 0 8px 8px
      height: 100%

      .value
        margin: 0 8px
</style>
